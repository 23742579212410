const Colors = {
    a: "#e21515",
    b: "#3C4048",
    c: "#B2B2B2",
    d: "#EAEAEA",
    e: "#FFF",
    wht: "#FFF",
    blk: "#000"
};

export default Colors;